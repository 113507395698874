// 1. include this mixin in your component.
// 2. include <loading-box
//              :loadingBox="loadingBox"
//            />

import LoadingBox from '@/components/modal/LoadingBox'

const LoadingBoxParent = {
  components: {
    LoadingBox
  },
  props: {
  },
  data () {
    return {
      loadingBox: {
        loadingBoxVisible: false,
        msg: null
      }
    }
  },
  methods: {
    openLoadingBox (msg) {
      this.loadingBox = {
        loadingBoxVisible: true,
        msg
      }
    },
    closeLoadingBox () {
      this.loadingBox = {
        loadingBoxVisible: false,
        msg: ''
      }
    }
  }
}

export default LoadingBoxParent
