var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600" },
      on: { "click:outside": _vm.cancelLicenseActivation },
      model: {
        value: _vm.dialog_activation,
        callback: function($$v) {
          _vm.dialog_activation = $$v
        },
        expression: "dialog_activation"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("h3", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-overview" }),
            _vm._v("License Activation ")
          ]),
          _c("table", { staticClass: "form-activation" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "25%" } }),
              _c("col")
            ]),
            _c("tr", [
              _c("th", [_vm._v("ACTIVE")]),
              _c("td", [_vm._v(_vm._s(_vm.license.active))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("LICENSE ID")]),
              _c("td", [_vm._v(_vm._s(_vm.license.licenseId))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("EXPIRY DATE")]),
              _c("td", [_vm._v(_vm._s(_vm.license.expiryDate))])
            ]),
            _c("tr", [
              _c("th", [_vm._v("ACTIVATE")]),
              _c("td", [
                _c("textarea", {
                  attrs: {
                    rows: "5",
                    placeholder: "Please enter the encoded license."
                  },
                  domProps: { value: _vm.licenseData.encodedLicense },
                  on: { keyup: _vm.handleEncodedLicenseKeyup }
                })
              ])
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleLicenseActivateClick }
                },
                [_vm._v(_vm._s(_vm.$t("Activation")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelLicenseActivation }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }