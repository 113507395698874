var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          key: _vm.loadingBox.loadingBoxVisible,
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.loadingBox.loadingBoxVisible,
            callback: function($$v) {
              _vm.$set(_vm.loadingBox, "loadingBoxVisible", $$v)
            },
            expression: "loadingBox.loadingBoxVisible"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.loadingBox.msg) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-progress-linear", {
                        attrs: { value: "15", indeterminate: "" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }