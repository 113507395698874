var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400" },
      on: { "click:outside": _vm.cancelPasswordChange },
      model: {
        value: _vm.dialog_passwordChange,
        callback: function($$v) {
          _vm.dialog_passwordChange = $$v
        },
        expression: "dialog_passwordChange"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("h3", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-overview" }),
            _vm._v(_vm._s(_vm.$t("Change Password")))
          ]),
          _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c(
                "v-col",
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("Old Password")) + " *")]),
                  _c("v-text-field", {
                    staticClass: "form-input mt-1",
                    attrs: {
                      type: "password",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      clearable: "",
                      solo: ""
                    },
                    model: {
                      value: _vm.passwordChangeData.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordChangeData, "oldPassword", $$v)
                      },
                      expression: "passwordChangeData.oldPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("New Password")) + " *")]),
                  _c("v-text-field", {
                    ref: "newPassword",
                    staticClass: "form-input mt-1",
                    attrs: {
                      value: _vm.passwordChangeData.newPassword,
                      rules: [_vm.rules.password],
                      type: "password",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      clearable: "",
                      solo: ""
                    },
                    on: { keyup: _vm.handleNewPasswordKeyup }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("Confirm Password")) + " *")]),
                  _c("v-text-field", {
                    ref: "confirmPassword",
                    staticClass: "form-input mt-1",
                    attrs: {
                      rules: [_vm.rules.passwordConfirm],
                      type: "password",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      clearable: "",
                      solo: ""
                    },
                    on: { keyup: _vm.handleConfirmPasswordKeyup },
                    model: {
                      value: _vm.passwordChangeData.confirmPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordChangeData, "confirmPassword", $$v)
                      },
                      expression: "passwordChangeData.confirmPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleChangePasswordBtn }
                },
                [_vm._v(_vm._s(_vm.$t("Change")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelPasswordChange }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }