<template>
  <div>
    <v-dialog
        v-model="loadingBox.loadingBoxVisible"
        :key="loadingBox.loadingBoxVisible"
        width="300"
        persistent
    >
      <v-card class="popup add_store_popup" align="center" justify="center">
        <v-row>
          <v-col>
            <span v-text="loadingBox.msg"></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-progress-linear value="15" indeterminate></v-progress-linear>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'LoadingBox',
  props: {
    loadingBox: {
      type: Object,
      required: true,
      default () {
        return {
          loadingBoxVisible: false,
          msg: ''
        }
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style>
</style>
